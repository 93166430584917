// router.js
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/HomePage.vue')
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('../views/HomePage.vue')
    },
    {
      path: '/clausesView',
      name: 'clausesView',
      component: () => import('../views/ClausesPage.vue')
    },
    {
      path: '/Statement',
      name: 'Statement',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/StatementPage.vue')
    }
  ]
});

export default router;